@import "mixins/mixins";
@import "common/var";

@include b(message) {
  min-width: $--message-min-width;
  box-sizing: border-box;
  border-radius: $--border-radius-base;
  border-width: $--border-width-base;
  border-style: $--border-style-base;
  border-color: $--border-color-lighter;
  position: fixed;
  left: 50%;
  top: 20px;
  transform: translateX(-50%);
  background-color: $--message-background-color;
  transition: opacity 0.3s, transform .4s;
  overflow: hidden;
  padding: $--message-padding;
  display: flex;
  align-items: center;

  @include when(center) {
    justify-content: center;
  }

  @include when(closable) {
    .el-message__content {
      padding-right: 16px;
    }
  }

  p {
    margin: 0;
  }

  @include m(info) {
    .el-message__content {
      color: $--message-info-color;
    }
  }

  @include m(success) {
    background-color: $--color-success-lighter;
    border-color: $--color-success-light;

    .el-message__content {
      color: $--message-success-color;
    }
  }

  @include m(warning) {
    background-color: $--color-warning-lighter;
    border-color: $--color-warning-light;

    .el-message__content {
      color: $--message-warning-color;
    }
  }

  @include m(error) {
    background-color: $--color-danger-lighter;
    border-color: $--color-danger-light;

    .el-message__content {
      color: $--message-danger-color;
    }
  }

  @include e(icon) {
    margin-right: 10px;
  }

  @include e(content) {
    padding: 0;
    font-size: 14px;
    line-height: 1;
    &:focus {
      outline-width: 0;
    }
  }

  @include e(closeBtn) {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    cursor: pointer;
    color: $--message-close-color;
    font-size: $--message-close-size;

    &:focus {
      outline-width: 0;
    }
    &:hover {
      color: $--message-close-hover-color;
    }
  }

  & .el-icon-success {
    color: $--message-success-color;
  }

  & .el-icon-error {
    color: $--message-danger-color;
  }

  & .el-icon-info {
    color: $--message-info-color;
  }

  & .el-icon-warning {
    color: $--message-warning-color;
  }
}

.el-message-fade-enter,
.el-message-fade-leave-active {
  opacity: 0;
  transform: translate(-50%, -100%);
}
