.d-inline{
    display:inline;
}
.float-right{
    float:right;
}
.button-link{
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    -webkit-appearance: none;
    text-align: center;
    outline: none;
    font-weight: 500;
    padding: 12px 20px;
    font-size: 14px;
    border-radius: 4px;
    color: #fff;
    background-color: $--color-primary;
    border-color: $--color-primary;
    text-decoration: none;
}
.button-link:hover, .button-link:focus {
    background: #bd4949;
    border-color: #bd4949;
    color: #fff;
}

.main-content {
    display: flow-root;
    padding: 0 0 0 0;
  }

/* Sidebar Styles */

#sidebar-wrapper {
    height: auto;
    margin: 0 auto -60px;
    padding: 0 0 60px;
  }
  .sidebar-nav {
    width: 200px;
    margin: 0 0 0 0px;
    padding: 0 0 0 0px;
    list-style: none;
  }
  
  .sidebar-nav li {
    text-indent: 20px;
    line-height: 40px;
  }
  
  .sidebar-nav li a, .dropdown ul li a {
    display: block;
    text-decoration: none;
  }
  
  .sidebar-nav li a:active, .sidebar-nav li a:focus {
    background-color: #e5e5e5;
  }
  
  .sidebar-nav li a:hover, .sidebar-nav li a:focus {
    background-color: #e5e5e5;
    font-weight: bold;
  }

  .sidebar-nav  .active{
    background-color: #e5e5e5;
    font-weight: bold;
  }
  
  .sidebar-nav>.sidebar-brand {
    height: 65px;
    font-size: 18px;
    line-height: 60px;
  }

  .wrapper {
    width: 1420px;
  }

  .body-container {
    margin: 0 auto;
  }
  
  @media  screen and (max-width: 990px) {
    .wrapper, .body-container {
      width: 970px;
    }
  }
  
  
  @media  screen and  (min-width: 990px) and (max-width: 1440px) {
    .wrapper, .body-container {
    width: 1260px;
    }

    #sidebar-wrapper {
      width: 250px;;
      float: left;
    }
  }

  @media  screen and (min-width: 1440px) {
    #sidebar-wrapper {
      width: 250px;;
      float: left;
    }

    .wrapper, .body-container {
      width: 1420px;
    }
  }

  

  /* Footer*/
  .primary-footer {
    bottom: 0;
    height: 2.5rem;
    float: left;
    width: 250px;
    position: fixed;    
    color: #AC1B1B;
    padding: 0 0 20px 0;

  }

  /* Dropdown Button */
.dropbtn {
  border: none;
  cursor: pointer;
}

/* The container <div> - needed to position the dropdown content */
.dropdown1 {
  position: relative;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute; 
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  text-decoration: none;
  display: block;
  margin-left: -40px;;
}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {display:block;}