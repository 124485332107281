@import "../common/var";

@include b(time-spinner) {
  &.has-seconds {
    .el-time-spinner__wrapper {
      width: 33.3%;
    }
  }

  @include e(wrapper) {
    max-height: 190px;
    overflow: auto;
    display: inline-block;
    width: 50%;
    vertical-align: top;
    position: relative;

    & .el-scrollbar__wrap:not(.el-scrollbar__wrap--hidden-default) {
      padding-bottom: 15px;
    }

    @include when(arrow) {
      box-sizing: border-box;
      text-align: center;
      overflow: hidden;

      .el-time-spinner__list {
        transform: translateY(-32px);
      }

      .el-time-spinner__item:hover:not(.disabled):not(.active) {
        background: $--color-white;
        cursor: default;
      }
    }
  }

  @include e(arrow) {
    font-size: 12px;
    color: $--color-text-secondary;
    position: absolute;
    left: 0;
    width: 100%;
    z-index: $--index-normal;
    text-align: center;
    height: 30px;
    line-height: 30px;
    cursor: pointer;

    &:hover {
      color: $--color-primary;
    }

    &.el-icon-arrow-up {
      top: 10px;
    }

    &.el-icon-arrow-down {
      bottom: 10px;
    }
  }

  @include e(input) {
    &.el-input {
      width: 70%;

      .el-input__inner {
        padding: 0;
        text-align: center;
      }
    }
  }

  @include e(list) {
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;

    &::after,
    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 80px;
    }
  }

  @include e(item) {
    height: 32px;
    line-height: 32px;
    font-size: 12px;
    color: $--color-text-regular;

    &:hover:not(.disabled):not(.active) {
      background: $--background-color-base;
      cursor: pointer;
    }

    &.active:not(.disabled) {
      color: $--color-text-primary;
      font-weight: bold;
    }

    &.disabled {
      color: $--color-text-placeholder;
      cursor: not-allowed;
    }
  }
}
