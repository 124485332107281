.container--login {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    height: 1px;
}

.login-panel {
    width: 30em;
    margin-top: -1em;
}

.login-actions {
    margin-top: 1.5em;
    display: flex;
    justify-content: space-between;
    align-items: center;
}