@import "../common/var";
@mixin button-plain($color) {
  color: $color;
  background: mix($--color-white, $color, 90%);
  border-color: mix($--color-white, $color, 60%);

  &:hover,
  &:focus {
    background: $color;
    border-color: $color;
    color: $--color-white;
  }

  &:active {
    background: mix($--color-black, $color, $--button-active-shade-percent);
    border-color: mix($--color-black, $color, $--button-active-shade-percent);
    color: $--color-white;
    outline: none;
  }

  &.is-disabled {
    &,
    &:hover,
    &:focus,
    &:active {
      color: mix($--color-white, $color, 40%);
      background-color: mix($--color-white, $color, 90%);
      border-color: mix($--color-white, $color, 80%);
    }
  }
}

@mixin button-variant($color, $background-color, $border-color) {
  color: $color;
  background-color: $background-color;
  border-color: $border-color;

  &:hover,
  &:focus {
    background: mix($--color-white, $background-color, $--button-hover-tint-percent);
    border-color: mix($--color-white, $border-color, $--button-hover-tint-percent);
    color: $color;
  }
  
  &:active {
    background: mix($--color-black, $background-color, $--button-active-shade-percent);
    border-color: mix($--color-black, $border-color, $--button-active-shade-percent);
    color: $color;
    outline: none;
  }

  &.is-active {
    background: mix($--color-black, $background-color, $--button-active-shade-percent);
    border-color: mix($--color-black, $border-color, $--button-active-shade-percent);
    color: $color;
  }

  &.is-disabled {
    &,
    &:hover,
    &:focus,
    &:active {
      color: $--color-white;
      background-color: mix($background-color, $--color-white);
      border-color: mix($border-color, $--color-white);
    }
  }

  &.is-plain {
    @include button-plain($background-color);
  }
}

@mixin button-size($padding-vertical, $padding-horizontal, $font-size, $border-radius) {
  padding: $padding-vertical $padding-horizontal;
  font-size: $font-size;
  border-radius: $border-radius;
  &.is-round {
    padding: $padding-vertical $padding-horizontal;
  }
}
