.form-input {
    @extend .el-input__inner;
}

.form-row {
    margin-bottom: 1em;
}

.form-label,
.el-form-item__label {
    font-size: 0.8em;
    text-transform: uppercase;
    font-weight: bold;
    color: $--color-primary;
    margin-bottom: 0.25em;
    display: inline-block;
}

.el-form-item {
    margin-bottom: 1em;
}

.el-select {
  display: block;
}

.el-form--label-top .el-form-item__label {
    display: inline-block;
    margin-bottom: 0.5em;
}

.form-button {
    @extend .el-button;
    @extend .el-button--primary;
}

.invalid-feedback,
.el-form-item__error {
    color: $--color-warning;
    font-size: 0.8em;
    margin-top: 0.25em;
}

.link-button {
    @extend .el-button;
    @extend .el-button--mini;

    text-decoration: none;
}