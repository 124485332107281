@import "mixins/mixins";
@import "mixins/_button";
@import "common/var";

@include b(radio-button) {
  position: relative;
  display: inline-block;
  outline: none;

  @include e(inner) {
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    vertical-align: middle;
    background: $--button-default-fill;
    border: $--border-base;
    font-weight: $--button-font-weight;
    border-left: 0;
    color: $--button-default-color;
    -webkit-appearance: none;
    text-align: center;
    box-sizing: border-box;
    outline: none;
    margin: 0;
    position: relative;
    cursor: pointer;
    transition: $--all-transition;

    @include button-size($--button-padding-vertical, $--button-padding-horizontal, $--button-font-size, 0);

    &:hover {
      color: $--color-primary;
    }

    & [class*="el-icon-"] {
      line-height: 0.9;

      & + span {
        margin-left: 5px;
      }
    }
  }

  &:first-child {
    .el-radio-button__inner {
      border-left: $--border-base;
      border-radius: $--border-radius-base 0 0 $--border-radius-base;
      box-shadow: none !important;
    }
  }

  @include e(orig-radio) {
    opacity: 0;
    outline: none;
    position: absolute;
    z-index: -1;
    left: -999px;

    &:checked {
      & + .el-radio-button__inner {
        color: $--radio-button-checked-color;
        background-color: $--radio-button-checked-fill;
        border-color: $--radio-button-checked-border-color;
        box-shadow: -1px 0 0 0 $--radio-button-checked-border-color;
      }
    }

    &:disabled {
      & + .el-radio-button__inner {
        color: $--button-disabled-color;
        cursor: not-allowed;
        background-image: none;
        background-color: $--button-disabled-fill;
        border-color: $--button-disabled-border;
        box-shadow: none;
      }
      &:checked + .el-radio-button__inner {
        background-color: $--radio-button-disabled-checked-fill;
      }
    }
  }

  &:last-child {
    .el-radio-button__inner {
      border-radius: 0 $--border-radius-base $--border-radius-base 0;
    }
  }

  &:first-child:last-child {
    .el-radio-button__inner {
      border-radius: $--border-radius-base;
    }
  }

  @include m(medium) {
    & .el-radio-button__inner {
      @include button-size($--button-medium-padding-vertical, $--button-medium-padding-horizontal, $--button-medium-font-size, 0);
    }
  }
  @include m(small) {
    & .el-radio-button__inner {
      @include button-size($--button-small-padding-vertical, $--button-small-padding-horizontal, $--button-small-font-size, 0);
    }
  }
  @include m(mini) {
    & .el-radio-button__inner {
      @include button-size($--button-mini-padding-vertical, $--button-mini-padding-horizontal, $--button-mini-font-size, 0);
    }
  }

  &:focus:not(.is-focus):not(:active){ /*获得焦点时 样式提醒*/
    box-shadow: 0 0 2px 2px $--radio-button-checked-border-color;
  }
}
