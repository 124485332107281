@import "mixins/mixins";

@include b(steps) {
  display: flex;

  @include m(simple) {
    padding: 13px 8%;
    border-radius: 4px;
    background: $--background-color-base;
  }

  @include m(horizontal) {
    white-space: nowrap;
  }

  @include m(vertical) {
    height: 100%;
    flex-flow: column;
  }
}
