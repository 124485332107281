// Custom theme values for element
$--color-primary: #AC1B1B;
$--color-text-secondary: #AC1B1B;
$--font-size-base: 15px;
$--font-size-small: 14px;
$--pagination-font-size: 14px;
$--color-warning: #C4683B;
$--border-color-base: #dbdde4;
$--background-color-base: #f0f0f0;
$--border-color-light: #dbdde4 !default;
$--border-color-lighter: #dbdde4 !default;
$--color-success: #AC1B1B !default;
$font-color: #333333;

body {
    font-family: IBM Plex Sans, sans-serif;
    font-size: 16px;
}

@import "normalize.css/normalize";
@import "partials/helpers";
@import 'element-theme/index';
@import "partials/typography";
@import "partials/layout";
@import "partials/form";
@import "partials/auth";
@import "partials/themifyIcons";
@import "partials/leases";
@import "custom";

.el-popup-parent--hidden {
    overflow: hidden;
}