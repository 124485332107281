@import "../common/var";

@include b(month-table) {
  font-size: 12px;
  margin: -1px;
  border-collapse: collapse;

  td {
    text-align: center;
    padding: 20px 3px;
    cursor: pointer;

    &.disabled .cell {
      background-color: $--background-color-base;
      cursor: not-allowed;
      color: $--color-text-placeholder;

      &:hover {
        color: $--color-text-placeholder;
      }
    }

    .cell {
      width: 48px;
      height: 32px;
      display: block;
      line-height: 32px;
      color: $--datepicker-color;
      margin: 0 auto;

      &:hover {
        color: $--datepicker-text-hover-color;
      }
    }

    &.current:not(.disabled) .cell {
      color: $--datepicker-active-color;
    }
  }
}
