// We can use these mixins to wrap any styles that we'd like
// to be phone, tablet, or desktop only
@mixin display-above($size) {
    @media only screen and (min-width: $size) {
        @content;
    }
}

@mixin padding-top-percent( $width, $height ) {
    padding-top: ( $height * 100 / $width ) * 1%;
}

// For those times when we need to tweak a layout
// in a single location.
$sizes: none 0, xsm 4px, sm 8px, md 18px, lg 35px, xlg 52px;
$sides: top, right, bottom, left;

@each $side in $sides {
    @each $size in $sizes {
        $title: nth($size, 1);
        $px: nth($size, 2);

        .padding-#{$side}-#{$title} {
            padding-#{$side}: $px !important;
        }

        .margin-#{$side}-#{$title} {
            margin-#{$side}: $px !important;
        }
    }
}

@mixin display-below($resolution) {
    @media handheld, only screen and (max-width: $resolution ) {
        @content;
    }
}

.center {
    text-align: center;
}