@import "mixins/mixins";
@import "mixins/utils";
@import "common/var";
@import "common/popup";

@include b(dialog) {
  position: relative;
  margin: 0 auto 50px;
  background: $--color-white;
  border-radius: $--border-radius-small;
  box-shadow: $--dialog-box-shadow;
  box-sizing: border-box;
  width: 50%;

  @include when(fullscreen) {
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    height: 100%;
    overflow: auto;
  }

  @include e(wrapper) {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    margin: 0;
  }

  @include e(header) {
    padding: $--dialog-padding-primary;
    padding-bottom: 10px;
  }

  @include e(headerbtn) {
    position: absolute;
    top: $--dialog-padding-primary;
    right: $--dialog-padding-primary;
    padding: 0;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: $--message-close-size;

    .el-dialog__close {
      color: $--color-info;
    }

    &:focus, &:hover {
      .el-dialog__close {
        color: $--color-primary;
      }
    }
  }

  @include e(title) {
    line-height: $--dialog-line-height;
    font-size: $--dialog-title-font-size;
    color: $--color-text-primary;
  }

  @include e(body) {
    padding: 30px 20px;
    color: $--color-text-regular;
    line-height: $--dialog-line-height;
    font-size: $--dialog-font-size;
  }

  @include e(footer) {
    padding: $--dialog-padding-primary;
    padding-top: 10px;
    text-align: right;
    box-sizing: border-box;
  }

  // 内容居中布局
  @include m(center) {
    text-align: center;

    @include e(body) {
      text-align: initial;
      padding: 25px ($--dialog-padding-primary + 5px) 30px;
    }

    @include e(footer) {
      text-align: inherit;
    }
  }
}

.dialog-fade-enter-active {
  animation: dialog-fade-in .3s;
}

.dialog-fade-leave-active {
  animation: dialog-fade-out .3s;
}

@keyframes dialog-fade-in {
  0% {
    transform: translate3d(0, -20px, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes dialog-fade-out {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(0, -20px, 0);
    opacity: 0;
  }
}
