@import "mixins/mixins";
@import "mixins/utils";
@import "common/var";

@include b(progress) {
  position: relative;
  line-height: 1;

  @include e(text) {
    font-size:14px;
    color: $--color-text-regular;
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
    line-height: 1;

    i {
      vertical-align: middle;
      display: block;
    }
  }

  @include m(circle) {
    display: inline-block;

    .el-progress__text {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      text-align: center;
      margin: 0;
      transform: translate(0, -50%);

      i {
        vertical-align: middle;
        display: inline-block;
      }
    }
  }

  @include m(without-text) {
    .el-progress__text {
      display: none;
    }

    .el-progress-bar {
      padding-right: 0;
      margin-right: 0;
      display: block;
    }
  }

  @include m(text-inside) {
    .el-progress-bar {
      padding-right: 0;
      margin-right: 0;
    }
  }

  @include when(success) {
    .el-progress-bar__inner {
      background-color: $--color-success;
    }

    .el-progress__text {
      color: $--color-success;
    }
  }

  @include when(exception) {
    .el-progress-bar__inner {
      background-color: $--color-danger;
    }

    .el-progress__text {
      color: $--color-danger;
    }
  }
}

@include b(progress-bar) {
  padding-right: 50px;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  margin-right: -55px;
  box-sizing: border-box;

  @include e(outer) {
    height: 6px;
    border-radius: 100px;
    background-color: $--border-color-lighter;
    overflow: hidden;
    position: relative;
    vertical-align: middle;
  }
  @include e(inner) {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background-color: $--color-primary;
    text-align: right;
    border-radius: 100px;
    line-height: 1;
    white-space: nowrap;

    @include utils-vertical-center;
  }

  @include e(innerText) {
    display: inline-block;
    vertical-align: middle;
    color: $--color-white;
    font-size: 12px;
    margin: 0 5px;
  }
}

@keyframes progress {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 32px 0;
  }
}
