[v-cloak] {
  display: none;
}

.wrapper {
    width: 1260px;
    margin: 0 auto;
    position: relative;
}

.island {
    width: 960px;
    margin: 0 auto;
    position: relative;
}

.island--md {
    width: 800px;
}

.island--sm {
    width: 640px;
}

.island--xsm {
    width: 400px;
}

body {
    margin: 0;
    color: #333333;
}

.body-container {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    justify-items: space-between;
    height: 1px;
}

.primary-header {
    padding: 0.15em 0;
    border-bottom: 1px solid #d7d7d7;
    min-height: 50px;

    .wrapper {
        margin-top: 0;
        margin-bottom: 0;
        text-align: right;
        font-size: 14px;
        color: #999999;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.body-container > main {
    flex: 1 1 auto;
    display: block;
}

.header-links {
    padding: 1.5em;

    a {
        color: $font-color;
        text-decoration: none;
        font-size: 0.9em;
    }
}

.primary-footer {
    text-align: center;
    font-size: 14px;
    color: #999999;
}

.logo {
    max-width: 220px;
    display: block;
    font-size: 1.7em;
    font-weight: bold;
    text-decoration: none;

    img {
        max-width: 100%;
    }
}

.login-status {
    display: inline-block;
    margin-right: 1em;
}

.primary-navigation {
    ul, li {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: inline-block;
    }

    li {
        border-left: 1px solid #d7d7d7;
        padding-left: 1em;

        &:not(:last-child) {
            margin-right: 1em;
        }
    }

    a {
        text-decoration: none;
    }

    .ti-view-list {
        position: relative;
        top: 0.12em;
    }
}


.column-actions .cell {
    text-align: right;
}

.el-pagination__total {
    display: block;
    text-align: center;
}

.el-table {
    font-size: 15px;
}

.el-table th > .cell {
    text-transform: uppercase;
    font-size: 14px;
}

.el-table__row--striped {
    td {
      background: #eeeeee !important;
    }
}

.warning-row td {
    background: #fcf0e4 !important;
    color: #c56839 !important;
}

.split-layout {
    display: flex;
    justify-content: space-between;

    > * {
        flex: 0 1 48%;
        box-sizing: border-box;
    }
}

.split-layout--vertical-center {
    align-items: center;
}

.split-layout--vertical-top {
    align-items: flex-start;
}

.panel {
    padding: 2em;
    box-sizing: border-box;
}

.panel--main {
    width: 60%;
    flex-basis: 60%;
}

.panel--gray {
    background-color: #EBEBEB;
    margin-left: 2em;
}

.flex-row {
    display: flex;
    justify-content: flex-start;
}

.flex-row--space-between {
    justify-content: space-between;

    > * {
        flex: 0 1 100%;

        &:not(:first-child) {
            margin-left: 1.5em;
        }
    }
}

.flex-item--auto-width {
    flex: 0 1 auto;
}

.flex-row--align-center {
    align-items: center;
}

.flex-row--justify-center {
    justify-content: center;
}

.el-alert--tip {
    padding: 0;
    background: transparent;

    .el-alert__title {
        font-size: 1rem;
        font-style: italic;
    }
}

.dropdown {
    position: relative;
    display: inline-block;
    color: $--color-primary;
    cursor: pointer;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #fafafa;
    padding-bottom: 10px;
    z-index: 1;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
  }

  .dropdown-content a {
    margin-top: 10px !important;
  }

  .dropdown span:hover {
        &::after {
            opacity: 1;
        }
}

  .dropdown span::after {
    content: '';
    display: block;
    width: 100%;
    bottom: -4px;
    height: 3px;
    -webkit-transition: all 60ms ease-in-out;
    transition: all 60ms ease-in-out;
    opacity: 0;
    background-color: #c21e1e;
    position: absolute;
    left: 0;
    z-index: 2;
}

  .dropdown:hover .dropdown-content {
    display: block;
  }

.subnav {
    margin-top: 2em;
    margin-bottom: 3.5em;

    a {
        display: inline-block;
        margin: 0 0.5em;
        text-decoration: none;
        padding-bottom: 0.18em;
        position: relative;

        &::after {
            content: '';
            display: block;
            width: 100%;
            bottom: -4px;
            left: 0;
            height: 3px;
            transition: all 60ms ease-in-out;
            opacity: 0;
            background-color: lighten( $--color-primary, 5% );
            position: absolute;
        }

        &:hover {
            &::after {
                opacity: 1;
                bottom: -2px;
            }
        }

        &.active {
            &::after {
                opacity: 1;
                background-color: $--color-primary;
                bottom: -2px;
            }
        }
    }
}

.el-step__line-inner {
    display: none;
}

.btn-link, .btn-link:hover {
    color:#AC1B1B
}

.loader {
    border: 16px solid #EBEBEB; 
    border-top: 16px solid #AC1B1B;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    animation: spin 2s linear infinite;
    left: 40%;
    position: absolute;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
