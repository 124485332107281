@import "mixins/mixins";
@import "progress";
@import "common/var";

@include b(upload) {
  display: inline-block;
  text-align: center;
  cursor: pointer;
  outline: none;
  @include e(input) {
    display: none;
  }

  @include e(tip) {
    font-size: 12px;
    color: $--color-text-regular;
    margin-top: 7px;
  }

  iframe {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    opacity: 0;
    filter: alpha(opacity=0);
  }

  /* 照片墙模式 */
  @include m(picture-card) {
    background-color: #fbfdff;
    border: 1px dashed #c0ccda;
    border-radius: 6px;
    box-sizing: border-box;
    width: 148px;
    height: 148px;
    cursor: pointer;
    line-height: 146px;
    vertical-align: top;

    i {
      font-size: 28px;
      color: #8c939d;
    }

    &:hover {
      border-color: $--color-primary;
      color: $--color-primary;
    }
  }
  &:focus {
    border-color: $--color-primary;
    color: $--color-primary;

    .el-upload-dragger {
      border-color: $--color-primary;
    }
  }
}

@include b(upload-dragger) {
  background-color: #fff;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  box-sizing: border-box;
  width: 360px;
  height: 180px;
  text-align: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  .el-icon-upload {
    font-size: 67px;
    color: $--color-text-placeholder;
    margin: 40px 0 16px;
    line-height: 50px;
  }

  + .el-upload__tip {
    text-align: center;
  }

  ~ .el-upload__files {
    border-top: $--border-base;
    margin-top: 7px;
    padding-top: 5px;
  }

  .el-upload__text {
    color: $--color-text-regular;
    font-size: 14px;
    text-align: center;

    em {
      color: $--color-primary;
      font-style: normal;
    }
  }

  &:hover {
    border-color: $--color-primary;
  }

  @include when(dragover) {
    background-color: rgba(32, 159, 255, .06);
    border: 2px dashed $--color-primary;
  }
}

@include b(upload-list) {
  margin: 0;
  padding: 0;
  list-style: none;

  @include e(item) {
    transition: all .5s cubic-bezier(.55,0,.1,1);
    font-size: 14px;
    color: $--color-text-regular;
    line-height: 1.8;
    margin-top: 5px;
    position: relative;
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;

    .el-progress {
      position: absolute;
      top: 20px;
      width: 100%;
    }

    .el-progress__text {
      position: absolute;
      right: 0;
      top: -13px;
    }

    .el-progress-bar {
      margin-right: 0;
      padding-right: 0;
    }

    &:first-child {
      margin-top: 10px;
    }

    & .el-icon-upload-success {
      color: $--color-success;
    }

    .el-icon-close {
      display: none;
      position: absolute;
      top: 5px;
      right: 5px;
      cursor: pointer;
      opacity: .75;
      color: $--color-text-regular;
      //transform: scale(.7);

      &:hover {
        opacity: 1;
      }
    }

    & .el-icon-close-tip {
      display: none;
      position: absolute;
      top: 5px;
      right: 5px;
      font-size: 12px;
      cursor: pointer;
      opacity: 1;
      color: $--color-primary;
    }
    
    &:hover {
      background-color: $--background-color-base;

      .el-icon-close {
        display: inline-block;
      }

      .el-progress__text {
        display: none;
      }
    }

    @include when(success) {
      .el-upload-list__item-status-label {
        display: block;
      }

      .el-upload-list__item-name:hover, .el-upload-list__item-name:focus {
        color: $--link-hover-color;
        cursor: pointer;
      }

      &:focus:not(:hover) {  /* 键盘focus */
        .el-icon-close-tip {
          display: inline-block;
        }
      }

      &:not(.focusing):focus, &:active { /* click时 */
        outline-width: 0;
        .el-icon-close-tip {
          display: none;
        }
      }

      &:hover, &:focus {
        .el-upload-list__item-status-label {
          display: none;
        }
      }
    }
  }

  @include when(disabled) {
    .el-upload-list__item:hover .el-upload-list__item-status-label {
      display: block;
    }
  }

  @include e(item-name) {
    color: $--color-text-regular;
    display: block;
    margin-right: 40px;
    overflow: hidden;
    padding-left: 4px;
    text-overflow: ellipsis;
    transition: color .3s;
    white-space: nowrap;

    [class^="el-icon"] {
      height: 100%;
      margin-right: 7px;
      color: $--color-text-secondary;
      line-height: inherit;
    }
  }

  @include e(item-status-label) {
    position: absolute;
    right: 5px;
    top: 0;
    line-height: inherit;
    display: none;
  }

  @include e(item-delete) {
    position: absolute;
    right: 10px;
    top: 0;
    font-size: 12px;
    color: $--color-text-regular;
    display: none;

    &:hover {
      color: $--color-primary;
    }
  }

  @include m(picture-card) {
    margin: 0;
    display: inline;
    vertical-align: top;

    .el-upload-list__item {
      overflow: hidden;
      background-color: #fff;
      border: 1px solid #c0ccda;
      border-radius: 6px;
      box-sizing: border-box;
      width: 148px;
      height: 148px;
      margin: 0 8px 8px 0;
      display: inline-block;

      .el-icon-check,
      .el-icon-circle-check {
        color: $--color-white;
      }

      .el-icon-close {
        display: none;
      }
      &:hover {
        .el-upload-list__item-status-label {
          display: none;
        }

        .el-progress__text {
          display: block;
        }
      }
    }

    .el-upload-list__item-name {
      display: none;
    }

    .el-upload-list__item-thumbnail {
      width: 100%;
      height: 100%;
    }

    .el-upload-list__item-status-label {
      position: absolute;
      right: -15px;
      top: -6px;
      width: 40px;
      height: 24px;
      background: #13ce66;
      text-align: center;
      transform: rotate(45deg);
      box-shadow: 0 0 1pc 1px rgba(0,0,0,0.2);

      i {
        font-size: 12px;
        margin-top: 11px;
        transform: rotate(-45deg);
      }
    }

    .el-upload-list__item-actions {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      cursor: default;
      text-align: center;
      color: #fff;
      opacity: 0;
      font-size: 20px;
      background-color: rgba(0, 0, 0, .5);
      transition: opacity .3s;
      &::after {
        display: inline-block;
        content: "";
        height: 100%;
        vertical-align: middle
      }

      span {
        display: none;
        cursor: pointer;
      }

      span + span {
        margin-left: 15px;
      }

      .el-upload-list__item-delete {
        position: static;
        font-size: inherit;
        color: inherit;
      }

      &:hover {
        opacity: 1;
        span {
          display: inline-block;
        }
      }
    }

    .el-progress {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      bottom: auto;
      width: 126px;

      .el-progress__text {
        top: 50%;
      }
    }
  }

  @include m(picture) {
    .el-upload-list__item {
      overflow: hidden;
      z-index: 0;
      background-color: #fff;
      border: 1px solid #c0ccda;
      border-radius: 6px;
      box-sizing: border-box;
      margin-top: 10px;
      padding: 10px 10px 10px 90px;
      height: 92px;

      .el-icon-check,
      .el-icon-circle-check {
        color: $--color-white;
      }

      &:hover {
        .el-upload-list__item-status-label {
          background: transparent;
          box-shadow: none;
          top: -2px;
          right: -12px;
        }

        .el-progress__text {
          display: block;
        }
      }

      &.is-success {
        .el-upload-list__item-name {
          line-height: 70px;
          margin-top: 0;
          i {
            display: none;
          }
        }
      }
    }

    .el-upload-list__item-thumbnail {
      vertical-align: middle;
      display: inline-block;
      width: 70px;
      height: 70px;
      float: left;
      position: relative;
      z-index: 1;
      margin-left: -80px;
    }

    .el-upload-list__item-name {
      display: block;
      margin-top: 20px;

      i {
        font-size: 70px;
        line-height: 1;
        position: absolute;
        left: 9px;
        top: 10px;
      }
    }

    .el-upload-list__item-status-label {
      position: absolute;
      right: -17px;
      top: -7px;
      width: 46px;
      height: 26px;
      background: #13ce66;
      text-align: center;
      transform: rotate(45deg);
      box-shadow: 0 1px 1px #ccc;

      i {
        font-size: 12px;
        margin-top: 12px;
        transform: rotate(-45deg);
      }
    }

    .el-progress {
      position: relative;
      top: -7px;
    }
  }
}

@include b(upload-cover) {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 10;
  cursor: default;
  @include utils-vertical-center;

  img {
    display: block;
    width: 100%;
    height: 100%;
  }

  @include e(label) {
    position: absolute;
    right: -15px;
    top: -6px;
    width: 40px;
    height: 24px;
    background: #13ce66;
    text-align: center;
    transform: rotate(45deg);
    box-shadow: 0 0 1pc 1px rgba(0,0,0,0.2);

    i {
      font-size: 12px;
      margin-top: 11px;
      transform: rotate(-45deg);
      color: #fff;
    }
  }

  @include e(progress) {
    display: inline-block;
    vertical-align: middle;
    position: static;
    width: 243px;

    + .el-upload__inner {
      opacity: 0;
    }
  }

  @include e(content) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @include e(interact) {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#000, .72);
    text-align: center;

    .btn {
      display: inline-block;
      color: $--color-white;
      font-size: 14px;
      cursor: pointer;
      vertical-align: middle;
      transition: $--md-fade-transition;
      margin-top: 60px;

      i {
        margin-top: 0;
      }

      span {
        opacity: 0;
        transition: opacity .15s linear;
      }

      &:not(:first-child) {
        margin-left: 35px;
      }

      &:hover {
        transform: translateY(-13px);

        span {
          opacity: 1;
        }
      }

      i {
        color: $--color-white;
        display: block;
        font-size: 24px;
        line-height: inherit;
        margin: 0 auto 5px;
      }
    }
  }

  @include e(title) {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: $--color-white;
    height: 36px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: normal;
    text-align: left;
    padding: 0 10px;
    margin: 0;
    line-height: 36px;
    font-size: 14px;
    color: $--color-text-primary;
  }

  + .el-upload__inner {
    opacity: 0;
    position: relative;
    z-index: 1;
  }
}
