h1 {
    font-size: 60px;
    font-weight: 200;
}

h3 {
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 0.1em;
    color: #767676;
}

p {
    margin-top: 0.45em;
    line-height: 1.5;
}

.large-text {
    font-size: 1.35em;
    font-weight: 300;
}

.small-text {
    font-size: 0.8em;
}

.form-status-text {
    font-style: italic;
    font-weight: 300;
    margin-top: 0.4em;
    margin-bottom: 1em;
}

.status-text {
    font-size: 14px;
    padding: 2px 4px;
    background-color: #d7d7d7;
    display: inline-block;
    vertical-align: middle;
    border-radius: 4px;
    position: relative;
    top: -2px;
}

.table-subtext {
    font-size: 0.8em;
    font-style: italic;
}

a, .link {
    color: #AC1B1B;

    &:hover {
        color: lighten( #AC1B1B, 5% );
    }
}

.button-link {
    background-color: transparent;
    display: inline;
    padding: 0;
    border: 0 none;
    cursor: pointer;
    @extend .link;
    outline: none;
}