.lease__header {
    background-color: #ECECEC;
    padding: 1.5em;
    margin-bottom: 3.5em;

    .lease__header-links {
        margin-bottom: 3em;
        font-size: 0.9em;
        width: 100%;

        a {
            text-decoration: none;
            width: auto;
            color: $font-color;
        }
    }

    .data-value {
        @extend .large-text;
        margin: 0;
    }

    .lease__title-row > div {
        &:not(:first-child) {
            margin-left: 3.5em;
        }
    }

    .lease__title-row .data-value {
        font-size: 1.8em;
        font-weight: 300;
    }

    .lease__subtitle-row {
        border-top: 1px solid #DADADA;
        padding-top: 1.5em;
        margin-top: 1.5em;

        > div {
            &:not(:first-child) {
                margin-left: 3.5em;
            }
        }
    }
}

.data-label {
    @extend .form-label;
}

.lease__other {
}

.lease__equipment {

    .data-label {
        margin: 0;
    }

    .flex-row > div {
        &:not(:first-child) {
            margin-left: 3.5em;
        }
    }

    .status-icon {
        height: 0.75em;
        width: 0.75em;
        box-sizing: border-box;
        display: inline-block;
        overflow: hidden;
        border-radius: 100%;
        position: relative;
        top: 0.05em;
        margin-right: 0.3em;
    }

    .status-icon--not_blocking {
        background-color: $--color-primary;
        border: 2px solid lighten( $--color-primary, 10% );
    }

    .status-icon--blocking {
        background-color: #C4683B;
        border: 2px solid lighten( #C4683B, 10% );
    }

    .status-icon-- {
        background-color: #a1a1a1;
        border: 2px solid lighten( #a1a1a1, 10% );
    }

    .lease__actions {
        margin-top: 0.75em;

        .el-form-item {
            margin-bottom: 0;
        }
    }
}

.edit-lease {
    .el-form--label-left {
        .el-form-item {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            border-radius: 4px;
            border: 1px solid #dbdde4;
            background-color: #F9F9F9;

            .el-form-item__label {
                margin: 0;
                width: 35%;
                padding-left: 0.75em;
            }

            .el-form-item__content {
                margin: 0;
                width: 60%;

                .el-input__inner {
                    border-width: 0;
                    border-left-width: 1px;
                    border-radius: 0;

                    &:hover, &:active, &:focus {
                        border-left-color: #dbdde4 !important;
                    }
                }
            }
        }
    }
}

.table__date,
.table__time {
    margin: 0;
    display: inline-block;
}

.table__time {
    font-style: italic;
    font-size: 0.8em;
}

.lease__device-log-status {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: 0.8em;

    i {
        display: inline-block;
        position: relative;
        top: 0.05em;
    }

    .ti-arrow-left {
        margin-right: 0.25em;
    }

    .ti-arrow-right {
        margin-left: 0.25em;
    }
}

.payment-status--overdue {
    color: $--color-warning;
}